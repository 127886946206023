<template>
	<div>
		<div class="search_container">
			<div>
				<span>解决方案名称：</span>
				<a-input @keydown.native.enter="handleSearch" class="input_width" placeholder="请输入" v-model="name" />
			</div>
			<div>
				<span>申请类型：</span>
				<a-select v-model="type" class="input_width">
					<a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</div>
			<div>
				<span>审核状态：</span>
				<a-select v-model="status" class="input_width">
					<a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</div>
			<div>
				<a-button @click="reset"> 重置 </a-button>
				<a-button style="margin-left: 20px" @click="handleSearch" type="primary"> 查询 </a-button>
			</div>
		</div>
		<div style="margin-top: 20px">
			<a-table :loading="loading" style="min-width: 1500px" :pagination="pagination"
				:rowKey="(record) => record.id" :columns="columns" :data-source="sourceData">
				<span slot="businessType" slot-scope="text">
					<span>{{businessTypeList[text]}}</span>
				</span>
				<span slot="applyType" slot-scope="text">
					<span>{{text==1?'上架':'下架'}}</span>
				</span>
				<span slot="restCheckTime" slot-scope="text,record">
					<span :style="{color:record.time.timeout?'red':''}">{{record.time.restTime}}</span>
				</span>
				<span slot="AuditStatus" slot-scope="text">
					<span>{{text.description}}</span>
				</span>
				<span slot="action" slot-scope="text, record">
					<a-button type="primary" @click="toDetail(record)">{{record.auditable?'审核':'查看详情'}}</a-button>
				</span>
			</a-table>
		</div>
	</div>
</template>
<script>
	import pagination from "@/mixins/Pagination";
	import { solutionCheckApi } from "@/api/solutionCheckApi.js";
	export default {
		mixins: [pagination],
		components: {},
		data() {
			return {
				loading: false,
				name: "",
				type: "",
				status: 1,
				typeList: [{
					name: "全部",
					value: "",
				}, {
					name: "上架",
					value: 1,
				}, {
					name: "下架",
					value: 2,
				}],
				statusList: [{
					name: "全部",
					value: "",
				}, {
					name: "待审核",
					value: 1,
				}, {
					name: "已驳回",
					value: 2,
				}, {
					name: "已下架",
					value: 3,
				}, {
					name: "已通过",
					value: 4,
				}],
				columns: [{
					title: "解决方案名称",
					dataIndex: "name",
					key: "name",
					scopedSlots: { customRender: "icon" },
					align: "center",
					ellipsis: true,
				}, {
					title: "业务类型",
					dataIndex: "businessType",
					key: "businessType",
					scopedSlots: { customRender: "businessType" },
					align: "center",
				}, {
					title: "申请类型",
					dataIndex: "applyType",
					key: "applyType",
					scopedSlots: { customRender: "applyType" },
					align: "center",
				}, {
					title: "申请人账号",
					dataIndex: "account",
					key: "account",
					align: "center",
				}, {
					title: "申请电话",
					dataIndex: "phone",
					key: "phone",
					align: "center",
				}, {
					title: "最新申请时间",
					align: "center",
					key: "applyTime",
					dataIndex: "applyTime",
				}, {
					title: "剩余审核时间",
					align: "center",
					key: "restTime",
					dataIndex: "restTime",
					scopedSlots: { customRender: "restCheckTime" },
				}, {
					title: "审核状态",
					align: "center",
					key: "status",
					dataIndex: "status",
					scopedSlots: { customRender: "AuditStatus" },
				}, {
					title: "操作",
					align: "center",
					key: "operation",
					scopedSlots: { customRender: "action" },
					width: "200px"
				}, ],
				sourceData: [],
				businessTypeList: {
					'1': '生产管控',
					'2': '安全环保',
					'3': '供应链管理',
					'4': '能源管理',
					'5': '资产管理',
					'0': '其他'
				}, //业务类型
			};
		},
		mounted() {
			this.getSourceData();
		},
		methods: {
			toDetail(record) {
				this.$router.push({ name: 'solutionDetail', query: { id: record.id } })
			},
			//重置
			reset() {
				this.name = ""
				this.type = ""
				this.status = ""
				this.pagination.current = 1
				this.pagination.pageSize = 10
				this.getSourceData()
			},
			// 查询表格数据
			getSourceData() {
				this.loading = true
				solutionCheckApi.getSolutionList({
					name: this.name,
					type: this.type,
					status: this.status,
					page: this.pagination.current,
					size: this.pagination.pageSize,
				}).then((res) => {
					if (res.success) {
						this.loading = false
						this.pagination.total = Number(res.data.total);
						this.sourceData = res.data.records;
						this.sourceData.forEach((item, index) => {
							item.key = index
						})
					} else {
						this.loading = false
						this.$message.error(res.errorMsg);
					}
				});
			},
			// 条件查询
			handleSearch() {
				this.pagination.current = 1
				this.getSourceData()
			},
			// 分页改变
			onSizeChange(current, size) {
				this.pagination.current = current
				this.pagination.pageSize = size
				this.getSourceData()
			},
			//分页
			onPage(current) {
				this.pagination.current = current
				this.getSourceData()
			},
		},
	};
</script>
<style lang="scss" scoped>
	.input_width {
		width: 240px;
	}

	.search_container {
		display: flex;
		justify-content: space-between;
		min-width: 1500px;
		overflow: auto;
	}

	.icon {
		width: 3rem;
	}
</style>
