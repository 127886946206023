import axios from "@/config/http";
// import qs from 'qs'
export const solutionCheckApi = {
	// 审核解决方案信息分页列表
	getSolutionList(params) {
		return axios.get("/cloud-platform-backend-facade/front-api/v1/audit-platform/solution/page", { params })
	},
	// 查询解决方案信息详情
	getSolutionDetail(params) {
		return axios.get("/cloud-platform-backend-facade/front-api/v1/audit-platform/solution/detail", { params })
	},
	// 下架解决方案
	RemoveSolution(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/solution/audit/remove", params )
	},
	// 驳回解决方案审核请求
	RejectSolution(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/solution/audit/reject", params )
	},
	// 通过解决方案审核请求
	PassSolution(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/solution/audit/pass", params )
	},
}
